<template>
  <div class="body mx-auto">
    <v-row dense class="pa-2">
      <v-col cols="12"
        ><div dense class="titles">
          <span class="text-h6 font-weight-bold" style="color: #643a6b">
            今日事件
          </span>
          <v-btn class="mx-4 white--text" @click="handleDialog" color="#313866">
            <v-icon>mdi-calendar-plus</v-icon>新增</v-btn
          >
        </div></v-col
      >
      <v-col
        v-for="(eventList, type) in groupedEvents()"
        :key="type"
        cols="12"
        sm="6"
        md="3"
        class="px-2"
      >
        <v-card
          :color="
            type === '隔離'
              ? '#0E21A0'
              : type === '探視'
              ? '#4D2DB7'
              : type === '請假'
              ? '#9D44C0'
              : '#EC53B0'
          "
          rounded="lg"
          height="200"
        >
          <v-card-title class="pa-2 white--text font-weight-bold">
            {{ type }}
          </v-card-title>
          <v-divider class="mx-2 my-0 white--text" />
          <v-card-text class="pa-2 white--text">
            <v-row
              dense
              align="center"
              v-for="description in eventList"
              :key="description"
              class="ma-1"
            >
              <v-icon color="white">mdi-circle-small</v-icon>
              {{ description }}
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense class="pa-2">
      <v-col cols="12">
        <div dense class="titles">
          <span class="text-h6 font-weight-bold" style="color: #643a6b">
            一週事件
          </span>
          <v-btn class="mx-4 white--text" @click="handleDialog" color="#313866"
            ><v-icon>mdi-calendar-plus</v-icon>新增</v-btn
          >
        </div>
      </v-col>
      <v-col v-for="(day, index) in 6" :key="index" cols="6" sm="4" md="2">
        <v-card height="200" width="100%">
          <v-card-title class="pa-2 font-weight-bold">
            {{ getDays(index) }}
          </v-card-title>
          <v-divider class="ma-0" />
          <v-card-text class="pa-1">
            <v-col
              v-for="event in getEventsForDay(getDate(index))"
              :key="event.id"
              class="pa-0"
              cols="12"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    :color="
                      event.event_type === '隔離'
                        ? '#0E21A0'
                        : event.event_type === '探視'
                        ? '#4D2DB7'
                        : event.event_type === '請假'
                        ? '#9D44C0'
                        : '#EC53B0'
                    "
                    class="white--text"
                  >
                    {{ listEvents(event) }}
                  </v-chip>
                </template>
                <div>
                  建立者：
                  <span class="font-weight-bold">{{
                    event.employee_name
                  }}</span>
                </div>
              </v-tooltip>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense class="pa-2">
      <v-col cols="12">
        <div dense class="titles">
          <span class="text-h6 font-weight-bold" style="color: #643a6b">
            備忘錄
          </span>
          <v-btn color="#313866" class="white--text mx-4" @click="openMemoEdit">
            <v-icon>mdi-pencil</v-icon>編輯
          </v-btn>
        </div>
      </v-col>
      <v-col>
        <v-card class="memo-display" min-height="200" color="amber lighten-4">
          <v-card-text class="text-h6 font-weight-bold black--text">
            <div>{{ memoContent }}</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="500">
      <v-card class="pa-2">
        <v-card-text class="pa-2">
          <v-row dense>
            <v-col cols="12" class="pa-2">
              <v-select
                outlined
                dense
                :items="names"
                label="選擇個案"
                hide-details=""
                v-model="case_name"
                @change="getCaseNum"
              ></v-select>
            </v-col>
            <v-col cols="12" class="pa-2">
              <v-select
                outlined
                dense
                :items="eventTypes"
                label="事件類型"
                hide-details=""
                v-model="event_type"
              ></v-select></v-col
            ><v-col cols="12" class="pa-2">
              <v-text-field
                outlined
                dense
                label="事由"
                hide-details=""
                v-model="description"
              ></v-text-field> </v-col
            ><v-col cols="12" class="pa-2">
              <v-menu
                ref="startMenu"
                v-model="startMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="startDate"
                    label="起始日期"
                    hide-details=""
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="startMenu = false"
                ></v-date-picker>
              </v-menu> </v-col
            ><v-col cols="12" class="pa-2">
              <v-select
                outlined
                dense
                :items="timeOptions"
                label="起始時間"
                hide-details=""
                v-model="startTime"
                @change="setEndTime"
              ></v-select></v-col
            ><v-col cols="12" class="pa-2">
              <v-menu
                ref="endMenu"
                v-model="endMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="endDate"
                    label="結束日期"
                    hide-details=""
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :disabled="handleSelect"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  @input="endMenu = false"
                ></v-date-picker>
              </v-menu> </v-col
            ><v-col cols="12" class="pa-2">
              <v-select
                outlined
                dense
                :items="timeOptions"
                label="結束時間"
                hide-details=""
                v-model="endTime"
                :disabled="handleSelect"
              ></v-select
            ></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-2 justify-center">
          <v-btn color="blue" class="white--text" @click="addEvents"
            >送出</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogMemoEdit" max-width="600px">
      <v-card>
        <v-card-title class="text-h5">編輯備忘錄</v-card-title>
        <v-card-text>
          <v-textarea
            outlined
            v-model="editMemoContent"
            placeholder="請輸入內容..."
            rows="10"
            auto-grow
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogMemoEdit = false"
            >取消</v-btn
          >
          <v-btn color="blue darken-1" text @click="saveMemo">送出</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row dense class="pa-2">
      <v-col cols="12">
        <div>
          <v-btn color="#141E46" class="white--text"> 2024-10 </v-btn>
          <span class="text-h6 font-weight-bold" style="color: #643a6b">
            月長輩異常狀況統計
          </span>
        </div>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="stat in stats"
        :key="stat.label"
        class="stat-item"
      >
        <v-icon large class="mr-2">{{ stat.icon }}</v-icon>
        {{ stat.label }}
        <div class="font-weight-bold" style="padding: 10px; color: red">
          {{ stat.value }}
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <div>
          <span class="text-h6 font-weight-bold" style="color: #643a6b">
            非計畫性住院高風險名單
          </span>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
        v-for="(role, index) in highRiskList"
        :key="index"
        class="pa-4"
      >
        <v-card hover class="high-risk-card">
          <v-card-title
            class="pa-0 pa-sm-2 justify-center text-h6 font-weight-bold"
          >
            <span class="card-title" style="color: #0079ff">
              {{ role.name }}
            </span>
          </v-card-title>
          <v-card-actions class="pa-0">
            <v-row no-gutters align="center">
              <v-col>
                <div class="text-center">
                  <v-icon :style="'color:red'">
                    {{ "mdi-emoticon-sad-outline" }}
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-card-actions>
          <v-card-text
            class="px-1 py-2 justify-center text-subtitle-1 font-weight-bold black--text"
          >
            <v-row no-gutters class="text-center">
              <v-col cols="6">
                <v-row no-gutters justify="center" align="center">
                  <v-icon color="#f44336">mdi-heart-pulse</v-icon>
                  <div v-if="role.blood_pressure != ''">
                    {{ role.blood_pressure }} mmHg
                  </div>
                  <div v-else>無資料</div>
                </v-row>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters justify="center" align="center">
                  <v-icon color="#d32f2f">mdi-thermometer</v-icon>
                  <div v-if="role.temperature != ''">
                    {{ role.temperature }} °C
                  </div>
                  <div v-else>無資料</div>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const moment = require("moment");
export default {
  data() {
    return {
      staff_id: "",
      dialog: false,
      eventTypes: ["送醫", "請假", "探視", "隔離"],
      event_type: "",
      description: "",
      case_name: "",
      case_num: "",
      dataList: [],
      startDate: new Date().toLocaleDateString().replaceAll("/", "-"),
      endDate: new Date().toLocaleDateString().replaceAll("/", "-"),
      startTime: "",
      endTime: "",
      startMenu: false,
      endMenu: false,
      timeOptions: this.generateTimeOptions(),
      events: [],
      handleSelect: false,
      memoContent: "",
      editMemoContent: "",
      dialogMemoEdit: false,

      // 異常統計資料
      stats: [
        { label: "跌倒", icon: "mdi-alert", value: "" },
        { label: "走失", icon: "mdi-run", value: "" },
        { label: "體溫", icon: "mdi-thermometer", value: "" },
        { label: "心率", icon: "mdi-heart-pulse", value: "" },
        { label: "血壓", icon: "mdi-heart", value: "" },
        { label: "血氧", icon: "mdi-percent", value: "" },
        { label: "血糖", icon: "mdi-blood-bag", value: "" },
        { label: "體重", icon: "mdi-weight", value: "" },
      ],

      // 非計畫住院高風險名單
      highRiskList: [
        // test data
        // {
        //   name: "吳O東",
        //   temperature: "36.5",
        //   blood_pressure: "120/80",
        //   heart_rate: "67",
        // },
      ],
    };
  },
  methods: {
    async getAlertCount() {
      const axios = require("axios");
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/attendance/get_irregular_event_count",
        headers: {},
      };
      try {
        let res = await axios.request(config);
        console.log(res.data);
        this.stats = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getCaseList() {
      const axios = require("axios");
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "/api/attendance/name_corresponding/",
        headers: {},
      };
      try {
        let res = await axios.request(config);
        this.dataList = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getEvents() {
      const now = new Date();
      let formattedTime = moment(now).format();
      const encodedISOString = encodeURIComponent(formattedTime);
      const axios = require("axios");
      let config = {
        method: "get",
        url: "/api/attendance/bulletin/?datetime=" + encodedISOString,
        header: {},
      };
      try {
        let res = await axios.request(config);
        console.log(res.data);
        this.events = res.data;
      } catch (error) {
        console.log(error);
      }
    },
    async addEvents() {
      let start = moment(
        this.startDate + " " + this.startTime,
        "YYYY-M-DD HH:mm"
      ).toDate();
      let end = moment(
        this.endDate + " " + this.endTime,
        "YYYY-M-DD HH:mm"
      ).toDate();
      // let end = moment(start).add(1, "hours").toDate();
      let formattedStart = moment(start).format();
      let formattedEnd = moment(end).format();
      const encodedStart = encodeURIComponent(formattedStart);
      const encodedEnd = encodeURIComponent(formattedEnd);
      const axios = require("axios");
      const qs = require("qs");
      let data = qs.stringify({
        event_type: this.event_type,
        description: this.description,
        start: formattedStart,
        end: formattedEnd,
        employee_id: this.staff_id,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `/api/attendance/events/${this.case_num}/`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      try {
        let res = await axios.request(config);
        console.log(res.data);
        this.getEvents();
        this.dialog = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getHighReiskList() {
      const axios = require("axios");
      const now = new Date();
      let formattedTime = moment(now).format();
      const encodedISOString = encodeURIComponent(formattedTime);

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url:
          "/api/attendance/get_high_risk_persons/?datetime=" + encodedISOString,
        header: {},
      };
      try {
        let res = await axios.request(config);
        console.log(res.data);
        this.highRiskList = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    getCaseNum() {
      this.case_num = this.dataList.find(
        (item) => item.name === this.case_name
      )?.case_num;
      console.log(this.case_num);
    },
    setEndTime() {
      this.endDate = this.startDate;
      if (this.event_type === "探視") {
        this.endTime = moment(this.startDate + " " + this.startTime)
          .add(1, "hours")
          .format("HH:mm");
        if (!this.timeOptions.includes(this.endTime)) {
          this.timeOptions.push(this.endTime);
          this.timeOptions.sort();
        }
        this.handleSelect = true;
      }
    },
    handleDialog() {
      this.case_name = "";
      this.case_num = "";
      this.event_type = "";
      this.description = "";
      this.startDate = new Date().toLocaleDateString().replaceAll("/", "-");
      this.endDate = new Date().toLocaleDateString().replaceAll("/", "-");
      this.startTime = "";
      this.endTime = "";
      this.handleSelect = false;
      this.dialog = true;
    },
    generateTimeOptions() {
      const options = [];
      for (let i = 8; i <= 17; i++) {
        const time = i < 10 ? `0${i}:00` : `${i}:00`;
        options.push(time);
      }
      return options;
    },
    getDays(index) {
      if (index == 0) {
        return "明日";
      } else {
        const date = new Date();
        date.setDate(date.getDate() + index + 1);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${month.toString().padStart(2, "0")}-${day
          .toString()
          .padStart(2, "0")}`;
      }
    },
    getDate(index) {
      const date = new Date();
      date.setDate(date.getDate() + index + 1);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
    },
    getEventsForDay(date) {
      const filteredEvents = this.events.filter((event) => {
        // Transfer string to object to manipulate date at locale timezone
        const eventLocalStart = new Date(event.start);
        const eventLocalEnd = new Date(event.end);
        const titleDate = new Date(date);

        if (titleDate >= eventLocalStart && titleDate <= eventLocalEnd) {
          return true;
        }
        return false;
      });
      return filteredEvents;
    },
    groupedEvents() {
      // Step 1: Get the user's timezone offset
      const userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
      // Step 2: Create a new Date object
      const now = new Date();
      // Step 3: Adjust the date object's time according to the user's timezone
      const adjustedDate = new Date(now.getTime() - userTimezoneOffset);
      // Step 4: Format the adjusted date into ISO string
      const today = adjustedDate.toISOString().split('T')[0];
      const groups = { 送醫: [], 請假: [], 探視: [], 隔離: [] };

      for (const event of this.events) {
        const eventDate = event.start.split("T")[0];
        const start = moment(event.start).format("HH:mm");
        const end = moment(event.end).format("HH:mm");
        if (eventDate === today) {
          if (groups.hasOwnProperty(event.event_type)) {
            let items = `${event.person} ${event.description}｜${start} - ${end}`;
            groups[event.event_type].push(items);
          }
        }
      }

      return groups;
    },
    listEvents(event) {
      const start = moment(event.start).format("HH:mm");
      return `${event.person} ${event.description}｜${start}`;
    },
    openMemoEdit() {
      this.editMemoContent = this.memoContent;
      this.dialogMemoEdit = true;
    },
    async getMemo() {
      const axios = require("axios");
      let config = {
        method: "get",
        url: "/api/attendance/text_memo/?datetime=" + this.startDate,
        header: {},
      };
      try {
        let res = await axios.request(config);
        this.memoContent = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async saveMemo() {
      const axios = require("axios");
      const qs = require("qs");
      let data = qs.stringify(
        {
          datetime: this.startDate,
          content: this.editMemoContent,
        },
        {
          encode: false,
        }
      );

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "/api/attendance/text_memo/",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      try {
        let res = await axios.request(config);
        this.memoContent = this.editMemoContent;
      } catch (error) {
        console.log(error);
      }
      this.dialogMemoEdit = false;
    },
  },
  mounted() {
    this.getCaseList();
    this.getEvents();
    this.getMemo();
    this.getAlertCount();
    this.getHighReiskList();
  },
  computed: {
    names() {
      return this.dataList.map((item) => item.name);
    },
  },
  created() {
    let staff = sessionStorage.getItem("staff");
    this.staff_id = JSON.parse(staff).id;
  },
};
</script>
<style scoped>
.body {
  width: 95%;
}
.weekly-card-height {
  height: 300px;
}

.titles {
  display: flex;
  align-items: center;
  padding: 8px 4px;
}

.memo-display div {
  white-space: pre-wrap;
}

.stat-item {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.high-risk-card {
  background-color: #f9f9e1;
  width: 90%;
  align-content: center;
}
</style>
